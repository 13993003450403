var $ = require( "jquery" );

const general = {

};

export default general;


(function($){
    $(document).ready(function () {

        const pics = [
            "./img/set-de-table.png",
            "./img/decoration-de-table.png",
            "./img/photophores.png",
            "./img/decoration-de-chambre.png",
            "./img/decoration-de-salon.png",
            "./img/ukulele.png",
        ];

        let $imgs = $('.js-switch-img');
        const size = $imgs.length;

        if (size) {
            let randomImg, picPicker, source, usedImg, nextImg, previousIndex, tmpIndex;
            previousIndex = -1;

            setInterval(function() {
                let $imgs = $('.js-switch-img');

                // Assure than image that will be switch is not the same than the previous
                if (previousIndex === -1) {
                    tmpIndex = Math.floor(Math.random() * size);
                } else {
                    while (tmpIndex === previousIndex) {
                        tmpIndex = Math.floor(Math.random() * size);
                    }
                }

                // Jquery get image
                randomImg = $($imgs.get(tmpIndex));
                previousIndex = tmpIndex;

                // Memorize all image already used currently
                usedImg = [];
                $.each($imgs, function(k, v) {
                   usedImg.push($(v).attr("src"));
                })

                // Random decision of which image will be used with taking care of currently use and not repeatly the same
                nextImg = false;
                while (!nextImg) {
                    picPicker = (Math.floor(Math.random() * 6));
                    source = pics[picPicker];

                    if (usedImg.indexOf(source) === -1) {
                        nextImg = true;
                    }
                }

                // Switching image with animation
                randomImg.fadeOut(2000);
                setTimeout(function () {
                    randomImg.attr('src', source);
                    randomImg.fadeIn(2000);
                }, 2000);
            }, 6000);
        }
    });
})($);


